import Table from '@/components/table/Table'
import {computed, reactive, ref, toRaw} from 'vue'
import ConstantAPI from '@/config/ConstantAPI'
import ActionButton from '@/components/action-button'
import {ModalStyled} from '@/components/modals/styled'
import {useForm} from 'ant-design-vue/es/form'
import {useStore} from 'vuex'
import {formatDate, greaterThanCurrent} from '@/util/common-utils'
import {required} from '@/util/validation'
import _ from 'lodash'
import {mapFields} from 'vuex-map-fields'
import {defaultButton} from '@/util/common-constant'
import Tag from '@/components/tags/Tag';
import dayjs from "dayjs";

const apiFetch = ConstantAPI.report.STATISTICAL
export default {
    name: 'report-new',
    components: {
        'cds-table': Table,
        ActionButton,
        ModalStyled,
        Tag
    },
    setup() {
        const columns = ref([
            {
                title: 'Tên bài viết',
                dataIndex: 'title',
                key: 'title',
                resizable: true,
                width: 360,
            },
            {
                title: 'Danh mục',
                dataIndex: 'categoryName',
                resizable: true,
                key: 'categoryName',
                width: 150,
            },
            {
                title: 'Người đăng',
                dataIndex: 'createdBy',
                key: 'createdBy',
                resizable: true,
                width: 260,
            },
            {
                title: 'Thời gian tạo',
                key: 'createdTime',
                sortDirections: ['descend', 'ascend'],
                resizable: true,
                sortable: true,
                dataIndex: 'createdTime',
                customRender({record}) {
                    return formatDate(record.createdTime);
                },
                align: 'center',
                width: 260,
            },
            {
                title: 'Thời gian kích hoạt',
                key: 'publishTime',
                sortDirections: ['descend', 'ascend'],
                resizable: true,
                sortable: true,
                dataIndex: 'publishTime',
                customRender({record}) {
                    return formatDate(record.publishTime);
                },
                align: 'center',
                width: 250,
            },
        ])
        const labelCol = {
            lg: 4,
            md: 4,
            xs: 24,
        }
        const wrapperCol = {
            lg: 10,
            md: 10,
            xs: 24,
        }
        const loading = computed(() => state.reportNews.loading)
        const table = ref(null)
        const {state, dispatch} = useStore()
        const {buttonActions} = defaultButton('reportNews')
        const buttonsForm = [
            {type: 'RESET'},
            {type: 'SEARCH'},
            {type: 'EXPORT_EXCEL', permission: 'report-article:export'},
        ];
        const formState = reactive({
            dateFrom: dayjs().startOf('day').subtract(30, 'days').toDate(),
            dateTo: dayjs().endOf('day').toDate(),
        });
        const rules = computed(() => {
            return {
                dateFrom: [
                    required('Từ ngày'),
                ],
                dateTo: [
                    required('Đến ngày'),
                ]
            };
        });
        const {resetFields, validate, validateInfos} = useForm(formState, rules)
        let paramFetch = _.cloneDeep(toRaw(formState))
        const setParamFetch = () => {
            paramFetch.dateFrom = formState.dateFrom;
            paramFetch.dateTo = formState.dateTo;
        }
        const resetForm = () => {
            resetFields()
            setParamFetch()
            table.value.resetAndFetch()
        }
        const onSearch = () => {
            validate().then(
                () => {
                    setParamFetch()
                    table.value.fetchData().then()
                },
                () => {
                }
            )
        }
        const onExportExcel = () => {
            validate().then(
                () => {
                    dispatch('reportNews/download',
                        {
                            dateFrom: formState.dateFrom,
                            dateTo: formState.dateTo
                        }).then()
                },
                () => {
                }
            )
        }

        const disabledDate = greaterThanCurrent;
        return {
            columns,
            apiFetch,
            labelCol,
            wrapperCol,
            formState,
            resetFields,
            resetForm,
            onSearch,
            onExportExcel,
            validate,
            validateInfos,
            rules,
            dispatch,
            table,
            paramFetch,
            loading,
            buttonsForm,
            buttonActions,
            disabledDate
        }
    },
    computed: {
        ...mapFields('reportNews', ['visible']),
    }
}

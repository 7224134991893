import _ from 'lodash';
import { DataService } from '../dataService/dataService';
import dayjs from "dayjs";

const validateRequired = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.reject(`${label} không được bỏ trống`);
  }
  return Promise.resolve();
};

export const required = (label) => {
  return {
    validator: (rule, value) => validateRequired(rule, value, label),
    trigger: ['change', 'blur'],
    whitespace: true,
    required: true,
  };
};

const validateMaxlength = async (_rule, value, maxlength, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if ((value + '').length > maxlength) {
    return Promise.reject(`${label} tối đa ${maxlength} ký tự`);
  }
  return Promise.resolve();
};

export const maxlength = (maxlength, label) => {
  return {
    validator: (rule, value) =>
      validateMaxlength(rule, value, maxlength, label),
    trigger: ['change', 'blur'],
  };
};

const validateMinlength = async (_rule, value, minlength, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if ((value + '').length < minlength) {
    return Promise.reject(`${label} tối thiểu ${minlength} ký tự`);
  }
  return Promise.resolve();
};

export const minlength = (minlength, label) => {
  return {
    validator: (rule, value) =>
      validateMinlength(rule, value, minlength, label),
    trigger: ['change', 'blur'],
  };
};

const callApi = async (_rule, value, label, url, params) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  try {
    const response = await DataService.get(url + '/' + value, params);
    return response.code === '200'
      ? Promise.resolve()
      : Promise.reject(`${label} đã tồn tại`);
  } catch {
    return Promise.reject(`${label} đã tồn tại`);
  }
};

export const asyncExistedRule = (label, url, params) => {
  return {
    validator: (rule, value) => callApi(rule, value, label, url, params),
    trigger: ['change', 'blur'],
  };
};

const validateEmail = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if (
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
      value + ''
    )
  ) {
    return Promise.resolve();
  }
  return Promise.reject(`${label} không đúng định dạng`);
};

export const emailRule = (label = 'Email') => {
  return {
    validator: (rule, value) => validateEmail(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

const validateNumber = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if (/^\d+$/.test(value + '')) {
    return Promise.resolve();
  }
  return Promise.reject(`${label} phải là số`);
};

export const numberRule = (label) => {
  return {
    validator: (rule, value) => validateNumber(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

export const patternRule = (pattern, label) => {
  return {
    pattern: pattern,
    message: `${label} không đúng định dạng`,
    trigger: ['change', 'blur'],
  };
};

export const rangeDatePickerRule = (label) => {
  return {
    validator: (rule, value) => rangeDatePicker(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

export const rangeDatePicker= async (_rule, value, label) => {
  if (!value && value.length < 2) {
    return Promise.resolve();
  }
  const fromDate = dayjs(value[0]).endOf('day');
  const toDate = dayjs(value[1]).endOf('day');
  const check = toDate.diff(fromDate, 'days') > 30;
  if (check) return Promise.reject(`${label} không quá 30 ngày`)
  return Promise.resolve();
};

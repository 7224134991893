import Table from '@/components/table/Table'
import { reactive, ref, toRaw } from 'vue'
import ConstantAPI from '@/config/ConstantAPI'
import ActionButton from '@/components/action-button'
import { ModalStyled } from '@/components/modals/styled'
import { useForm } from 'ant-design-vue/es/form'
import { formatDate } from '@/util/common-utils'
import _ from 'lodash'
import { defaultButton } from '@/util/common-constant'
import Tag from '@/components/tags/Tag';
import dayjs from "dayjs";
import { required } from "@/util/validation";

const apiFetch = ConstantAPI.report.LOG
export default {
    name: 'report-new',
    components: {
        'cds-table': Table,
        ActionButton,
        ModalStyled,
        Tag
    },
    setup() {
        const columns = ref([
            {
                title: 'Người gửi',
                dataIndex: 'aNumber',
                key: 'aNumber',
                resizable: true,
                width: 160,
            },
            {
                title: 'Người nhận',
                dataIndex: 'bNumber',
                resizable: true,
                key: 'bNumber',
                width: 150,
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                key: 'content',
                resizable: true,
            },
            {
                title: 'Ngày tạo',
                key: 'createdTime',
                sortDirections: ['descend', 'ascend'],
                resizable: true,
                sortable: true,
                dataIndex: 'createdTime',
                customRender({record}) {
                    return formatDate(record.createdTime);
                },
                align: 'center',
                width: 160,
            },
        ])
        const labelCol = {
            lg: 8,
            md: 8,
            xs: 24,
        };
        const wrapperCol = {
            lg: 16,
            md: 16,
            xs: 24,
        };
        const table = ref(null)
        const formRef = ref(null)
        const {buttonActions} = defaultButton('report')
        const buttonsForm = [
            {type: 'RESET'},
            {type: 'SEARCH'},
        ];
        const formState = reactive({
            msisdn: '',
            dateFrom: dayjs().startOf('day').subtract(30, 'days').toDate(),
            dateTo: dayjs().endOf('day').toDate(),
        });
        const rangeDatePicker= async () => {
            if (!formState.dateFrom || !formState.dateTo) {
                return Promise.resolve();
            }
            const fromDate = dayjs(formState.dateFrom).endOf('day');
            const toDate = dayjs(formState.dateTo).endOf('day');
            const check = toDate.diff(fromDate, 'days') > 30;
            if (check) return Promise.reject(`Từ ngày cách đến ngày không quá 30 ngày`)
            return Promise.resolve();
        };
        const rules = ref({
            dateFrom: [
                required('Từ ngày'),
                {
                validator: rangeDatePicker,
                trigger: ['change', 'blur'],
            }],
            dateTo: [required('Đến ngày')]
        });
        const {resetFields, validate, validateInfos} = useForm(formState, rules)
        let paramFetch = _.cloneDeep(toRaw(formState))
        const setParamFetch = () => {
            paramFetch.msisdn = formState.msisdn;
            paramFetch.dateFrom = formState.dateFrom;
            paramFetch.dateTo = formState.dateTo;
        }
        const resetForm = () => {
            resetFields()
            setParamFetch()
            table.value.resetAndFetch()
        }
        const onSearch = () => {
            validate().then(
                () => {
                    setParamFetch()
                    table.value.fetchData().then()
                },
                () => {
                }
            )
        }
        return {
            formRef,
            columns,
            apiFetch,
            labelCol,
            wrapperCol,
            formState,
            resetFields,
            resetForm,
            onSearch,
            validate,
            validateInfos,
            rules,
            table,
            paramFetch,
            buttonsForm,
            buttonActions,
        }
    },
    watch: {
        'formState.dateTo'() {
            this.$refs.formRef.validate().then()
        }
    }
}

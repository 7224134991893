<template>
  <cds-main class="">
    <cds-cards title="Tìm kiếm">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="30">
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Số điện thoại" name="msisdn">
              <cds-input class="ant-input-sm" v-model:value="formState.msisdn" :maxlength="15" show-count
                         size="small"
                         placeholder="Số điện thoại"
                         first-focus
                         @press-enter="onSearch"/>
            </a-form-item>
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <cds-from-to-date
                :allow-clear="false"
                from-name="dateFrom"
                to-name="dateTo"
                v-model:dateFrom="formState.dateFrom"
                v-model:dateTo="formState.dateTo"
            />
          </a-col>
        </a-row>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          @onClear="resetForm"
          @onSearch="onSearch"
      />
    </cds-cards>
    <cds-cards title="Danh sách Log">
      <cds-table
          ref="table"
          index-column
          key-field="id"
          pagination
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
      >
      </cds-table>
    </cds-cards>
  </cds-main>

</template>

<script src="./logs-controller.js"></script>

<style scoped>

</style>

<template>
  <cds-main class="">
    <cds-cards title="Tìm kiếm">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
      >
        <a-col :xxl="16" :xl="16" :lg="16" :md="16" :xs="24">
            <cds-from-to-date
                v-model:dateFrom="formState.dateFrom"
                :from-name="'dateFrom'"
                v-model:dateTo="formState.dateTo"
                :to-name="'dateTo'"
            />
        </a-col>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          :loading="loading"
          @onClear="resetForm"
          @onSearch="onSearch"
          @onExportExcel="onExportExcel"
      />
    </cds-cards>
    <cds-cards title="Danh sách Tin tức">
      <cds-table
          ref="table"
          index-column
          key-field="id"
          pagination
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
      >
      </cds-table>
    </cds-cards>
  </cds-main>

</template>

<script src="./news-controller.js"></script>

<style scoped>

</style>
